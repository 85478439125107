<template>
  <div class="mb-3">
    <h5 class="mb-1">Legenda:</h5>
    <div class="d-flex align-items-center text-muted fs-sm">
      <span class="bg-info rounded-pill p-1 me-2"></span> Aguardando Confirmação
      <span class="bg-success rounded-pill p-1 ms-2 me-2"></span> Agendado
      <span class="bg-danger rounded-pill p-1 ms-2 me-2"></span> Cancelado
      <span class="bg-primary rounded-pill p-1 ms-2 me-2"></span> Compareceu
      <span class="bg-yellow rounded-pill p-1 ms-2 me-2"></span> Não Compareceu
    </div>
  </div>
  <FullCalendar :options="calendarOptions">
    <template v-slot:eventContent="arg">
      <div
        class="w-100 p-1 cursor-pointer"
        :class="colorStatusLabel(arg.event.extendedProps.status)"
        title="Visualizar Agendamento"
      >
        <div v-if="currentView === 'dayGridMonth'">
          <p class="mb-1 text-wrap">
            <strong>{{ arg.event.title }}</strong>
          </p>
          <p class="mb-1 text-wrap">
            <strong>Tipo:</strong> {{ arg.event.extendedProps.type }}
          </p>
          <p class="mb-1 text-wrap">
            <strong>Clínica:</strong> {{ arg.event.extendedProps.unity }}
          </p>
          <p class="mb-0">
            <strong>Horário:</strong>
            {{
              arg.timeText.length === 2 ? `${arg.timeText}:00` : arg.timeText
            }}
          </p>
        </div>
        <div v-else>
          <p class="mb-1 text-wrap">
            <small>
              <strong>
                {{
                  arg.timeText.length === 2
                    ? `${arg.timeText}:00`
                    : arg.timeText
                }}
              </strong>
              | {{ arg.event.title }}
            </small>
          </p>
        </div>
      </div>
    </template>
  </FullCalendar>
</template>

<script>
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import ptbrLocale from '@fullcalendar/core/locales/pt-br'
import { colorStatusLabel } from '@/utils/strings'

export default {
  name: 'CalendarPlot',

  props: {
    schedules: {
      type: Array,
      required: true,
    },
    detailsView: {
      type: String,
      required: false,
    },
  },

  components: {
    FullCalendar,
  },

  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        },
        initialView: 'dayGridMonth',
        views: {
          dayGridMonth: {
            dayMaxEventRows: 4,
          },
          timeGridWeek: {
            eventMaxStack: 1,
          },
          timeGridDay: {
            eventMaxStack: 1,
          },
        },
        events: this.schedules,
        editable: false,
        selectable: false,
        locale: ptbrLocale,
        viewDidMount: this.handleViewChange,
        eventClick: this.handleEventClick,
      },
      currentView: 'dayGridMonth',
      colorStatusLabel,
    }
  },

  methods: {
    handleViewChange(info) {
      this.currentView = info.view.type
    },

    handleEventClick(_event) {
      this.$router.push({
        name: this.detailsView,
        params: { uuid: _event.event.id },
      })
    },
  },
}
</script>

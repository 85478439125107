const CalendarStatusEnum = {
  AGUARDANDO_COLABORADOR: 'E',
  AGUARDANDO_CREDENCIAMENTO: 'F',
  AGENDADO: 'S',
  CANCELADO: 'C',
  COMPARECEU: 'P',
  NAO_COMPARECEU: 'N',
}

export default CalendarStatusEnum

const statusLabel = (code, name) => {
  switch (code) {
    case 'E':
      return `<span class="badge bg-warning">${name}</span>`
    case 'F':
      return `<span class="badge bg-info">${name}</span>`
    case 'S':
      return `<span class="badge bg-success">${name}</span>`
    case 'C':
      return `<span class="badge bg-danger">${name}</span>`
    case 'P':
      return `<span class="badge bg-primary">${name}</span>`
    case 'N':
      return `<span class="badge bg-yellow text-dark">${name}</span>`
    default:
      return '<span class="badge bg-secondary">Indefinido</span>'
  }
}

const colorStatusLabel = (code) => {
  switch (code) {
    case 'E':
      return 'bg-wrning text-dark'
    case 'F':
      return 'bg-info text-dark'
    case 'S':
      return 'bg-success text-dark'
    case 'C':
      return 'bg-danger text-white'
    case 'P':
      return 'bg-primary text-dark'
    case 'N':
      return 'bg-yellow text-dark'
    default:
      return 'bg-secondary dark'
  }
}

const statusCollaboratorLabel = (name) => {
  switch (name) {
    case 'Ativo':
      return `<span class="badge bg-success">${name}</span>`
    case 'Inativo':
      return `<span class="badge bg-danger">${name}</span>`
    case 'Afastado':
      return `<span class="badge bg-yellow text-dark">${name}</span>`
    default:
      return '<span class="badge bg-secondary">Indefinido</span>'
  }
}

const typeLabel = (code, name) => {
  switch (code) {
    case 'C':
      return `<span class="badge bg-teal">${name}</span>`
    case 'D':
      return `<span class="badge bg-indigo">${name}</span>`
    default:
      return '<span class="badge bg-secondary">Indefinido</span>'
  }
}

export { statusLabel, colorStatusLabel, statusCollaboratorLabel, typeLabel }
